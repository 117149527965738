import { Injectable, inject } from '@angular/core'
import { FormControl, FormGroup, Validators } from '@angular/forms'
import { tap } from 'rxjs'
import { ReleaseNoteApiService } from '../services/release-note-api.service'

@Injectable({
    providedIn: 'root',
})
export class ReleaseNoteFormService {
    private releaseNoteApiService = inject(ReleaseNoteApiService)

    releaseNoteForm: FormGroup = new FormGroup({
        version: new FormControl<string>('', [Validators.required, Validators.minLength(3)]),
        title: new FormControl<string>('', [Validators.required]),
        content: new FormControl<string>('', [Validators.required]),
        releaseDate: new FormControl<Date>(null, [Validators.required]),
    })

    get hasTextareaMinLengthError() {
        return this.releaseNoteForm.get('version')?.hasError('minlength')
    }

    submitReleaseNote() {
        if (this.releaseNoteForm.invalid) {
            throw new Error('Please fill in the required fields.')
        }
        let { releaseDate, ...data } = this.releaseNoteForm.value
        const dateTime = new Date(releaseDate).toString()
        data = { ...data, ...{ releaseDate: dateTime } }
        return this.releaseNoteApiService.create(this.releaseNoteForm.value).pipe(
            tap((update) => {
                this.releaseNoteForm.reset()
                return update
            }),
        )
    }
}
